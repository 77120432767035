<template>
  <div class="container">
    <sub-header :pathList="pathList" :title="title"></sub-header>
    <div id="contents" class="contents">
      <div id="insert">
        <div class="title-sort-box">
          <h3>{{ $t('rule.insert-title') }}</h3>
          <p class="red-txt" v-html="$t('common.required-input-guide')"></p>
        </div>
        <!--/title-sort-box-->
        <article class="board-view">
          <div class="alarm-rule-wrapper">
            <ValidationObserver ref="form">
              <form id="insertAlarmRuleForm">
                <table class="alarm-rule-table">
                  <colgroup>
                    <col style="width:15%" />
                    <col style="width:32%" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <td colspan="2"></td>
                    </tr>
                    <tr>
                      <th>
                        <label class="ic-star table-label">
                          {{ $t('rule.insert-item1') }}
                        </label>
                      </th>
                      <td>
                        <span class="isf-with">
                          <text-input
                            :rules="{
                              required: true,
                              regex: /[0-9A-Za-z\-_]{4,20}/,
                            }"
                            :label="$t('rule.insert-item1')"
                            name="alarmRuleId"
                            maxlength="20"
                            dataType="LITERAL"
                            v-model.trim="insert.alarmRuleId"
                          ></text-input>
                          <button
                            type="button"
                            class="bt-md bt-default"
                            @click="doCheckUsable()"
                          >
                            {{ $t('rule.insert-item1-btn-usable') }}
                          </button>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label class="ic-star table-label">
                          {{ $t('rule.insert-item2') }}
                        </label>
                      </th>
                      <td>
                        <text-input
                          :rules="{
                            required: true,
                            regex: /[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|_-]{1,100}/,
                          }"
                          :label="$t('rule.insert-item2')"
                          name="alarmRuleNm"
                          maxlength="100"
                          dataType="LITERAL"
                          v-model.trim="insert.alarmRuleNm"
                        ></text-input>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label class="ic-star table-label">
                          {{ $t('rule.insert-item3') }}
                        </label>
                      </th>
                      <td>
                        <table class="alarm-rule-dtl-table">
                          <colgroup>
                            <col style="width:16%" />
                            <col style="width:30%" />
                            <col style="width:22%" />
                            <col style="width:26%" />
                            <col style="width:6%" />
                          </colgroup>
                          <thead>
                            <tr>
                              <th></th>
                              <th>{{ $t('rule.insert-item3-head1') }}</th>
                              <th>{{ $t('rule.insert-item3-head2') }}</th>
                              <th>{{ $t('rule.insert-item3-head3') }}</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(ruleDtl, index) in insert.ruleDtlVOList"
                              :key="index"
                            >
                              <td>
                                {{ $t('rule.insert-item3') + (index + 1) }}
                              </td>
                              <td>
                                <select
                                  class="isf term-sel"
                                  v-model="ruleDtl.alarmItemCode"
                                >
                                  <option value="">
                                    {{
                                      $t('rule.insert-item3-head1-condition1')
                                    }}
                                  </option>
                                  <option
                                    v-for="(alarmRuleItem,
                                    index) in alarmRuleItems"
                                    :key="index"
                                    :value="alarmRuleItem.codeName"
                                  >
                                    {{ alarmRuleItem.description }}
                                  </option>
                                </select>
                              </td>
                              <td>
                                <select
                                  class="isf term-sel"
                                  v-model="ruleDtl.scopeCode"
                                >
                                  <option value="">
                                    {{
                                      $t('rule.insert-item3-head2-condition1')
                                    }}
                                  </option>
                                  <option
                                    v-for="(ruleScope, index) in ruleScopeList"
                                    :key="index"
                                    :value="ruleScope.value"
                                  >
                                    {{ ruleScope.name }}
                                  </option>
                                </select>
                              </td>
                              <td>
                                <input
                                  type="number"
                                  class="isf"
                                  v-model="ruleDtl.ruleValue"
                                />
                              </td>
                              <td>
                                <input
                                  type="button"
                                  class="bt-line22"
                                  :class="
                                    index + 1 === insert.ruleDtlVOList.length
                                      ? 'bt-num-plus'
                                      : 'bt-num-minus'
                                  "
                                  value="+"
                                  @click="manageRow($event, index)"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label class="ic-star table-label">
                          {{ $t('rule.insert-item4') }}
                        </label>
                      </th>
                      <td>
                        <select
                          class="isf term-sel"
                          id="genderCode"
                          name="genderCode"
                          style="width: 110px;"
                          v-model="insert.genderCode"
                        >
                          <option value="">
                            {{ $t('rule.insert-item4-condition1') }}
                          </option>
                          <option value="M">
                            {{ $t('rule.insert-item4-condition2') }}
                          </option>
                          <option value="F">
                            {{ $t('rule.insert-item4-condition3') }}
                          </option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label class="ic-star table-label">
                          {{ $t('rule.insert-item5') }}
                        </label>
                      </th>
                      <td>
                        <select
                          class="isf term-sel"
                          id="alarmTyCode"
                          name="alarmTyCode"
                          style="width: 174px;"
                          v-model="insert.alarmTyCode"
                        >
                          <option value="">
                            {{ $t('rule.insert-item5-condition1') }}
                          </option>
                          <option value="AND">
                            {{ $t('rule.insert-item5-condition2') }}
                          </option>
                          <option value="OR">
                            {{ $t('rule.insert-item5-condition3') }}
                          </option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label class="ic-star table-label">
                          {{ $t('rule.insert-item6') }}
                        </label>
                      </th>
                      <td>
                        <text-input
                          :rules="{
                            required: true,
                          }"
                          type="number"
                          :label="$t('rule.insert-item6')"
                          name="alarmCycleValue"
                          v-model.trim="insert.alarmCycleValue"
                          maxlength="11"
                          dataType="NUMBER"
                        ></text-input>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label class="ic-star table-label">
                          {{ $t('rule.insert-item7') }}
                        </label>
                      </th>
                      <td>
                        <text-input
                          :rules="{
                            required: true,
                            regex: /[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|_-]{1,1000}/,
                          }"
                          :label="$t('rule.insert-item7')"
                          name="alarmMsgCn"
                          maxlength="1000"
                          dataType="LITERAL"
                          v-model.trim="insert.alarmMsgCn"
                        ></text-input>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </ValidationObserver>
          </div>
          <div class="btns-bottom">
            <button type="button" class="bt-md bt-default" @click="goToList()">
              <span>
                {{ $t('common.btn-cancel') }}
              </span>
            </button>
            <button type="button" class="bt-md bt-red" @click="checkValidate()">
              <span>
                {{ $t('common.btn-ok') }}
              </span>
            </button>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from '@/components/common/TextInput.vue';
import { ValidationObserver } from 'vee-validate';
import { RULE_SCOPE_LIST } from '@/store/properties';
import { fetchExistAlarmRuleId, insertRule } from '@/api/subscriptionApi';

export default {
  data() {
    return {
      pathList: [
        this.$i18n.t('menu.home'),
        this.$i18n.t('menu.subscriptionAdmin'),
        this.$i18n.t('subMenu.rule'),
      ],
      title: {
        mainTitle: this.$i18n.t('menu.subscriptionAdmin'),
        smallTitle: this.$i18n.t('menu.subscriptionAdmin-eng'),
      },
      insert: {
        alarmRuleId: '',
        alarmRuleNm: '',
        alarmTyCode: '',
        alarmCycleValue: 0,
        alarmMsgCn: '',
        genderCode: '',
        ruleDtlVOList: [
          {
            alarmItemCode: '',
            scopeCode: '',
            ruleValue: '',
          },
        ],
      },
      ruleScopeList: RULE_SCOPE_LIST,
      isCheckedUsable: false,
    };
  },
  components: {
    TextInput,
    ValidationObserver,
  },
  methods: {
    async checkValidate() {
      const response = await this.$refs.form.validate();
      if (!response) {
        this.$dialogs.alert(this.$i18n.t('common.invalid-required'));
        return false;
      }

      if (!this.isCheckedUsable) {
        this.$dialogs.alert(this.$i18n.t('rule.insert-alert-valid-usable'));
        return false;
      }

      let isRuleDtlPassed = true;
      let failureIdx;
      for (let i in this.insert.ruleDtlVOList) {
        const ruleDtl = this.insert.ruleDtlVOList[i];
        const { alarmItemCode, scopeCode, ruleValue } = ruleDtl;
        if (!alarmItemCode || !scopeCode || !ruleValue) {
          isRuleDtlPassed = false;
          failureIdx = i;
          break;
        }
      }

      if (!isRuleDtlPassed) {
        this.$dialogs.alert(
          `${this.$i18n.t('rule.insert-alert-valid-condition1')} ${Number(
            failureIdx,
          ) + 1}${this.$i18n.t('rule.insert-alert-valid-condition2')}`,
        );
        return false;
      }

      if (!this.insert.genderCode) {
        this.$dialogs.alert(this.$i18n.t('rule.insert-alert-valid-gender'));
        return false;
      }

      if (!this.insert.alarmTyCode) {
        this.$dialogs.alert(
          this.$i18n.t('rule.insert-alert-valid-alarmTyCode'),
        );
        return false;
      }
      this.doInsertRule();
    },
    async doInsertRule() {
      const alarmRuleId = this.insert.alarmRuleId;
      const alarmRuleNm = this.insert.alarmRuleNm;
      const alarmTyCode = this.insert.alarmTyCode;
      const alarmCycleValue = this.insert.alarmCycleValue;
      const alarmMsgCn = this.insert.alarmMsgCn;
      const genderCode = this.insert.genderCode;
      const ruleDtlVOList = this.insert.ruleDtlVOList;
      let ruleDtls = [];

      ruleDtlVOList.forEach(ruleDtlVO => {
        const { alarmItemCode, scopeCode, ruleValue } = ruleDtlVO;
        ruleDtls.push({ alarmItemCode, scopeCode, ruleValue });
      });

      ruleDtls.push({
        alarmItemCode: 'gender',
        scopeCode: '==',
        ruleValue: genderCode,
      });

      const reqObj = {
        alarmRuleId,
        alarmRuleNm,
        alarmTyCode: alarmTyCode === 'AND' ? '&&' : '||',
        ruleTyCode: 'ALARM',
        alarmCycleValue: Number(alarmCycleValue),
        alarmMsgCn,
        ruleDtlVOList: ruleDtls,
      };

      try {
        const { data } = await insertRule(reqObj);
        const { valid } = data;
        if (valid) {
          await this.$dialogs.alert(
            this.$i18n.t('rule.insert-alert-success-register'),
          );
          this.$router.push({ name: 'rule' });
        } else {
          await this.$dialogs.alert(
            this.$i18n.t('rule.insert-alert-failure-register'),
          );
          this.$router.push({ name: 'rule' });
        }
      } catch (error) {
        console.log('error', error);
      }
    },
    async doCheckUsable() {
      const alarmRuleId = this.insert.alarmRuleId;
      if (!alarmRuleId) {
        this.$dialogs.alert(
          this.$i18n.t('rule.insert-alert-valid-alarmRuleId'),
        );
        return false;
      }

      try {
        const { data } = await fetchExistAlarmRuleId(alarmRuleId);
        const { valid } = data;
        if (valid) {
          this.$dialogs.alert(this.$i18n.t('rule.insert-alert-success-usable'));
          this.isCheckedUsable = true;
        } else {
          this.$dialogs.alert(this.$i18n.t('rule.insert-alert-failure-usable'));
          this.isCheckedUsable = false;
        }
      } catch (error) {
        console.log('error', error);
      }
    },
    manageRow(event, index) {
      const className = event.target.className;
      if (className.indexOf('bt-num-plus') > -1) {
        this.appendRow();
      } else {
        this.removeRow(index);
      }
    },
    appendRow() {
      this.insert.ruleDtlVOList.push({
        alarmItemCode: '',
        scopeCode: '',
        ruleValue: '',
      });
    },
    removeRow(index) {
      this.insert.ruleDtlVOList.splice(index, 1);
    },
    goToList() {
      this.$router.push({ name: 'rule' });
    },
  },
  computed: {
    alarmRuleItems() {
      return this.$store.state.subscription.alarmRuleItems;
    },
  },
  watch: {
    'insert.alarmRuleId': function() {
      this.isCheckedUsable = false;
    },
  },
};
</script>

<style scoped>
@import url('./rule.css');
</style>
